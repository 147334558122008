body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Inter Variable', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}